@import url("https://fonts.googleapis.com/css2?family=Work+Sans&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Iceland&display=swap");
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap');

.App {
  padding-top: 12.5vh;
  display: flex;
  flex-direction: column;
  font-family: "Work Sans", sans-serif;
  align-items: center;
  color: white;
  > div {
    background-color: #212121;
    border-radius: 5rem;
    width: 46%;
    padding: 4rem 6rem;
    margin-bottom: 12.5vh;
  }
}


/* Class-level formatting */ 
.TitleCard {
  display: flex;
  width: 100%;
}
.projects {
  .Card {
    p:first-child {
      font-family: 'IBM Plex Mono', monospace;  
    }
  }
}
.hero {
  display: flex;
  align-content: center;
}

/* List styling */ 
ul {
  column-count: 2;
  padding: 0;
}
li {
  list-style: none;
}

/* Layout */
.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 5px;
}
.main-width {
  grid-column: 1/12;
}
.footer {
  text-align: center;
}

/* Global Variables */
:root {
  --color-green: #6fdea9;
  --color-seagreen: #6a9997;
  --color-riverbed: #414e5a;
  --color-seablue: #a4caeb;
  --color-seaviolet: #8a9ed4;
}
/* Color Related */
.green {
  color: var(--color-green);
}
.blue {
  background: #7296b3;
}
.teal {
  background: #008080;
}

/* Links */
a {
  color: var(--color-seablue);
  text-decoration-line: none;
}
a:visited {
  color: var(--color-seaviolet);
}



/* Shapes */
.circle {
  height: 2em;
  width: 2em;
  border-radius: 50%;
  align-self: center;
  flex-shrink: 0;
  flex-grow: 0;
  margin-right: 1rem;
}
.rotated-square {
  height: 1.5em;
  width: 1.5em;
  align-self: center;
  flex-shrink: 0;
  flex-grow: 0;
  background: #d69a9a;
  transform: rotate(-45deg);
  margin-right: 1rem;
}
.dividing-line {
  margin-left: 20px;
  margin-right: 20px;
  align-self: center;
  width: 80%;
  border-bottom: 1px solid white;
}


/* Text related */
.text {
  font-size: 16pt;
}
.title-text {
  font-size: 22pt;
}


/* Image related */
svg,
path {
  width: 2rem;
  height: 2rem;
  margin: 0 .5rem;
  fill: white;
}

img {
  margin-bottom: 2rem;
  margin-top: 4rem;
}
.grid-img-sm {
  width: 100%;
  grid-column: 1 / span 3;
}
.grid-img-md {
  width: 100%;
  grid-column: 1 / span 6;
}
.img-small {
  height: 30vh;
}
.img-md {
  width: 50%;
  height: 50%;
}
@media (max-width: 1024px) {
  .grid {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-column-gap: 5px;
  }
  .main-width {
    grid-column: 1/11;
  }
  .App {
    // color:orange;
    > div {
      width: 56%;
      padding: 3rem 3rem;
    }
  }
  img {
    margin-bottom: 1rem;
    margin-top: 2rem;
  }
}

@media (max-width: 768px) {
  .text {
    font-size: 10pt;
  }
  .title-text {
    font-size: 18pt;
  }
  .grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 5px;
  }
  .main-width {
    grid-column: 1/7;
  }
  .grid-img-sm {
    width: 100%;
    grid-column: 1 / span 2;
  }
  .App {
    > div {
      width: 68%;
      // padding: 4rem 4rem;
    }
  }
}






